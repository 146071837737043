<template>
  <div class="product-counter border border-radius10 px-2 mx-3" :style="disabled ? 'opacity: 0.5' : ''">
    <button @click="() => {if (disabled) return; decrement()}" >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
           class="bi bi-dash" viewBox="0 0 16 16">
        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
      </svg>
    </button>
    <span class="mx-2"> {{value.quantity}} </span>
    <button @click="() => {if (disabled) return; increment()}">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
           class="bi bi-plus" viewBox="0 0 16 16">
        <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg>
    </button>
  </div>
</template>

<script>

export default {
  name: "cartQuantityInput",
  props: {
    value: Object
  },
  data() {
    return {
      disabled: false,
    }
  },
  methods: {
    decrement() {
      let item = {
        ...this.value
      };
      if (item.quantity > 1) {
        this.disabled = true
        this.$axios.post(`/api/cart/add/${item.variety_id}`, {
          number: 1,
          unit: item.variety.product.unit,
          remove: 1
        })
            .then((response) => {
              this.$store.commit('header/pushCart', response.data.data)
            })
            .catch((error) => {
              this.$root.error_notification(error)
            })
            .finally(() => {
              this.disabled = false;
            })
      }

      
    },
    increment() {
      let item = {
        ...this.value
      };
      if (item.quantity == item.variety.in_storage) {
        this.$root.warning_notification(' از این کالا تنها ' + item.variety.in_storage + '  عدد موجود است ')
      } else {
        this.disabled = true
        this.$axios.post(`/api/cart/add/${item.variety_id}`, {
          number: 1,
          unit: item.variety.product.unit,
        })
            .then((response) => {
              this.$store.commit('header/pushCart', response.data.data)
            })
            .catch((error) => {
              this.$root.error_notification(error)
            })
            .finally(() => {
              this.disabled = false;
            })
      }
      
    },
  }
}
</script>

<style scoped>

</style>