<template>
    <article class="d-flex flex-wrap justify-content-between  align-items-center py-4 mx-3 fontsize11">
        <div v-if="value.variety" class="d-flex ml-2 mb-xl-0 mb-3 variety">
            <router-link
                :to="{name:'product.detail' , params:{id: value.variety.product.id , slug: value.variety.product.slug}}"
                class="cart-modal-img border-radius7 overflow-hidden ml-2">
                <img :src="value.variety.image" class="w-100 h-100" alt="">
            </router-link>
            <div class="text-right ">
                <router-link
                    :to="{name:'product.detail' , params:{id: value.variety.product.id , slug: value.variety.product.slug}}"
                    class="fontsize12 weight-bold text-color-444 mb-1">
                    {{value.variety.fullTitle}}
                </router-link>
                <CartColor :value="value" class="mb-1"></CartColor>
                <div class="mb-1 mt-1">
                    <span class="ml-1 text-color-666">قیمت :</span>
                    <span class="text-color-666"> {{ value.price | price }} تومان</span>
                </div>
                <div v-if="value.message" class=" mt-1">
                    <span class="ml-1 text-danger"> * {{value.message}}</span>
                </div>
                <div class="text-right d-md-none ">
                    <span class="text-color-red weight-bold">{{value.amount | price}}</span>
                    <span class="fontsize11 text-color-999 mr-1">تومان</span>
                </div>
            </div>
        </div>
        <div class="text-right d-md-flex d-none">
            <span class="text-color-444 weight-bold">{{value.amount | price}}</span>
            <span class="fontsize11 text-color-999 mr-1">تومان</span>
        </div>
        <div class="d-flex align-items-baseline">
            <span class="d-md-none">تعداد سفارش:</span>
            <CartQuantityInput :value="value"></CartQuantityInput>
            <CartDeleteButton class="bg-none" :value="value"></CartDeleteButton>
        </div>
    </article>
</template>
<script>
    import CartColor from './cartColor'
    import CartQuantityInput from "./cartQuantityInput";
    import CartDeleteButton from "./cartDeleteButton";

    export default {
        components: {
            CartDeleteButton,
            CartQuantityInput,
            CartColor
        },
        props: ['value'],
    }
</script>
<style scoped>
    .variety {
        max-width: 60%;
    }

    
    @media screen and (max-width: 746px) {
        .variety {
            max-width: unset;
        }
    }
</style>